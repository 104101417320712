import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import shopStatus from '@/constants/shop-status'
import { formatCurrency } from '@core/utils/filter'

export default function useShopList() {
  const listData = ref([])
  const totalListData = ref(0)

  const tableColumns = [
    { text: 'CODE', value: 'shopCode', align: 'center' },
    { text: 'MERCHANT NAME', value: 'companyName' },
    { text: 'SHOP NAME', value: 'shopName' },
    { text: 'SHOP URL', value: 'shopUrl', sortable: false },
    {
      text: 'STATUS', value: 'statusCode', align: 'center', sortable: false,
    },
  ]

  const searchQuery = ref('')
  const shopFilter = ref(null)
  const statusFilter = ref(null)
  const shopFilterData = ref([])
  const statusFilterData = ref([])

  const loading = ref(false)

  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })

  const selectedRows = ref([])
  const pageNumber = ref(1)
  const pageSize = ref(10)

  const isAddNewSideBarActive = ref(false)

  // fetch data
  const fetchList = () => {
    loading.value = true

    store
      .dispatch('onboarding-shops/fetchList', {
        q: searchQuery.value,
        options: options.value,
        status: statusFilter.value,
        shopId: shopFilter.value,
        pageNumber: pageNumber.value,
        pageSize: pageSize.value,
      })
      .then(response => {
        const { records, count } = response.data
        listData.value = records
        totalListData.value = count
      })
      .catch(error => error).finally(() => {
        loading.value = false
      })
  }

  watch([options], () => {
    selectedRows.value = []
    pageNumber.value = options.value.page
    pageSize.value = options.value.itemsPerPage
    fetchList()
  })

  const applyFilters = () => {
    fetchList()
  }

  const resetFilters = () => {
    searchQuery.value = ''
    shopFilter.value = null
    statusFilter.value = null
    pageNumber.value = 1
  }

  const fetchConfig = () => {
    loading.value = true
    store
      .dispatch('onboarding-shops/fetchConfig')
      .then(response => {
        const config = response.data
        statusFilterData.value = config.shopStatus
        shopFilterData.value = config.shops
      })
      .catch(error => error).finally(() => {
        loading.value = false
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveStatusVariant = status => {
    if (status === shopStatus.ENABLED) return 'success'
    if (status === shopStatus.DISABLED) return 'secondary'

    return 'primary'
  }

  return {
    listData,
    totalListData,
    tableColumns,
    searchQuery,
    shopFilter,
    shopFilterData,
    statusFilter,
    statusFilterData,
    isAddNewSideBarActive,
    loading,
    options,
    selectedRows,
    formatCurrency,
    applyFilters,
    resetFilters,
    fetchList,
    fetchConfig,
    resolveStatusVariant,
  }
}
